<template lang="pug">
b-dropdown#priceFilter.m-2(text="Precio", variant="dropdown", ref="dropdown")
  b-dropdown-form(style="width:350px;")
    b-row
      b-col
        .h5.mb-4 Rango de precio
        vue-slider(
          v-model="priceSlider",
          :enable-cross="false",
          :min="0",
          :max="100000000",
          :interval="500000"
          :tooltip-formatter="formatter"
        )
  b-dropdown-divider
  b-dropdown-form
    b-row
      b-col(cols="6")
        label Desde
        b-form-input.w-100(type="number", v-model="priceSlider[0]", min="1", max="100000000")
      b-col(cols="6")
        label Hasta
        b-form-input.w-100(type="number", v-model="priceSlider[1]", min="1", max="100000000")
  b-dropdown-divider
  b-dropdown-form
    b-row
      b-col.text-right
        b-button(variant="outline-okcarpremium" @click="closeDropdownPrice") Cancelar
        b-button(variant="okcarpremium ml-1"	@click="changePrice") Aplicar
</template>
<script>
import { mapState, mapMutations } from "vuex";
import VueSlider from "vue-slider-component";

export default {
  name: "FilterPrice",
  components: {
    VueSlider,
  },
  data() {
    return {
      priceSlider: [0, 100000000],
      formatter: (v) => `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
    };
  },
  mounted() {
    if (this.$route.query.prices) {
      this.priceSlider = this.$route.query.prices
        .split(",", 2)
        .map((num) => parseInt(num, 10));
      this.activeButton();
    }
    this.SET_PRICES(this.priceSlider);
    this.closeDropdownPrice();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  computed: {
    ...mapState("filter", ["keywords", "products", "filters"]),
  },
  methods: {
    ...mapMutations("filter", [
      "SET_PRICES",
      "SET_PAGE",
      "CLEAR_PRODUCTS",
      "CHANGE_TYPE",
    ]),
    changePrice() {
      this.SET_PRICES(this.priceSlider.map((num) => parseInt(num, 10)));
      this.activeButton();
      this.closeDropdownPrice();
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
    activeButton() {
      const pricesButtonFilter = document.querySelector("#priceFilter button");
      pricesButtonFilter.classList.add("btn-active");
    },
    closeDropdownPrice() {
      const pricesDropdownFilter = document.querySelector(
        "#priceFilter ul.dropdown-menu"
      );
      pricesDropdownFilter.classList.remove("show");
    },
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.priceSlider) {
        query.prices = JSON.stringify(this.priceSlider).slice(1, -1);
      } else {
        delete query.prices;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vue-slider-component/lib/theme/default.scss";
@import "@/assets/scss/_variables";

#priceFilter {
  > button {
    border: 1px solid #e8e8e8;
    color: #999999;
  }
  .vue-slider {
    &-process {
      background-color: $primary;
    }
    &-dot {
      height: 25px !important;
      width: 25px !important;
      &-tooltip-inner {
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}
</style>
